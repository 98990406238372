import axios from 'axios';
import { getApiURL } from '../utilities/general';
const API_URL = getApiURL();

class AuthenticationService {
  constructor() {
    this.getAuthUser = this.getAuthUser.bind(this);
    this.getToken = this.getToken.bind(this);
    this.setCompany = this.setCompany.bind(this);
    this.getCompany = this.getCompany.bind(this);
    this.parsedCompany = {};
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getCompany() {
    if (this.parsedCompany && this.parsedCompany.id) return this.parsedCompany;
    let companySelected = {};
    try {
      companySelected = JSON.parse(localStorage.getItem('company'));
      this.parsedCompany = companySelected;
    } catch (err) {}
    return companySelected;
  }

  setCompany(company) {
    this.parsedCompany = company;
    return localStorage.setItem('company', JSON.stringify(company));
  }

  getAuthUser() {
    return localStorage.getItem('user');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isAuthenticated() {
    return this.getAuthUser() !== null;
  }

  signIn(username, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}login/`, { email: username, password: password })

        .then((data) => {
          const token = data.data.token.replace(/^"(.*)"$/, '$1');
          const user = data.data;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          if (user.company) {
            localStorage.setItem('company', JSON.stringify(user.company));
          }
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  signUp(signUpData) {
    const { firstName, lastName, password, email } = { ...signUpData };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}users/`, {
          firstName,
          lastName,
          password,
          email,
          role: 'user',
        })

        .then((data) => {
          resolve(data.data.user);
        });
    });
  }

  signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    window.location.href = '/login';
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
